import React from 'react';
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube, FaLinkedinIn } from 'react-icons/fa';

import logo from '../images/logo.svg';
import styles from './Footer.module.scss';

const Footer = (): React.ReactElement => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.logoContainer}>
          <a target="_blank" rel="noreferrer" href="https://www.42lisboa.com/">
            <img src={logo} alt="" />
          </a>
        </div>
        <div className={styles.socialMediaContainer}>
          <a
            className={styles.socialMediaLink}
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/42lisboa"
          >
            <FaFacebook className={styles.icon}></FaFacebook>
          </a>
          <a
            className={styles.socialMediaLink}
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/42Lisboa"
          >
            <FaTwitter className={styles.icon}></FaTwitter>
          </a>
          <a
            className={styles.socialMediaLink}
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/42lisboa/"
          >
            <FaInstagram className={styles.icon}></FaInstagram>
          </a>
          <a
            className={styles.socialMediaLink}
            target="_blank"
            rel="noreferrer"
            href="https://www.youtube.com/channel/UCww4NqJBtcDVGN5im-fIEtQ"
          >
            <FaYoutube className={styles.icon}></FaYoutube>
          </a>
          <a
            className={styles.socialMediaLink}
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/school/42-lisboa/"
          >
            <FaLinkedinIn className={styles.icon}></FaLinkedinIn>
          </a>
        </div>
        <div className={styles.copyrightContainer}>
          <span className={styles.span}>Copyright © 2022 | Todos os direitos reservados</span>
          <span className={styles.span}>
            Powered by{' '}
            <a
              className={styles.moscaLink}
              target="_blank"
              rel="noreferrer"
              href="https://moscadigital.pt/?utm_source=openhouse.42lisboa.com"
            >
              Mosca Digital
            </a>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
