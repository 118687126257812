import React, { useEffect } from 'react';
import objectFitImages from 'object-fit-images';

import '../styles/typography.scss';
import '../styles/main.scss';

import ogImage from '../images/ogImage.png';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import Hero from '../components/Hero';
import VideoSection from '../components/VideoSection';
import About from '../components/About';
import OpenHouse from '../components/OpenHouse';
import Statistics from '../components/Statistics';

const IndexPage = () => {
  useEffect(() => {
    objectFitImages();
  }, []);
  return (
    <Layout>
      <SEO
        title="Vem conhecer a 42 Lisboa"
        description="Open House online da 42 Lisboa, a escola de programação que oferece uma alternativa a todos aqueles que não encontram o seu espaço no ensino superior tradicional"
        url="https://openhouse.42lisboa.com"
        imageUrl={ogImage}
      />
      <Hero></Hero>
      <VideoSection></VideoSection>
      <About></About>
      <Statistics></Statistics>
      <OpenHouse></OpenHouse>
    </Layout>
  );
};

export default IndexPage;
