import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import heroImage from '../images/hero42Lisboa.jpg';
import styles from './Hero.module.scss';

const Hero = (): React.ReactElement => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <div className={styles.titleWrapper}>
              <h1>DESCOBRE COMO É SER ALUNO DA 42 LISBOA</h1>
            </div>
            <p className={styles.description}>
              Vem visitar a 42 Lisboa dia 7 de maio para conheceres a nossa escola, os nossos
              alunos, a nossa equipa, e descobrires como é aprender numa escola sem professores, sem
              horários, sem propinas.
            </p>
            <AnchorLink href="#visit-fake-anchor" className={styles.link}>
              <button className={styles.button}>Marcar visita</button>
            </AnchorLink>
          </div>
        </div>
        <div className={styles.imageContainer}>
          <img src={heroImage} alt=" " />
        </div>
      </div>
    </div>
  );
};

export default Hero;
