import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import styles from './About.module.scss';

const About = (): React.ReactElement => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "about.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <BackgroundImage
      id="about"
      className={styles.wrapper}
      fluid={data.file.childImageSharp.fluid}
      alt=""
    >
      <div className={styles.overlay}>
        <div className={styles.container}>
          <div className={styles.content}>
            <h2>
              A ESCOLA DO FUTURO <br />
              ESTÁ EM LISBOA
            </h2>
            <p>
              A 42 é uma das melhores escolas de programação do mundo, com mais de 10.000 alunos em
              todo o mundo.
            </p>
            <p>
              Aqui aprende-se de forma diferente, desenvolvendo projetos, em grupo, ganhando pontos
              e passando de nível, como se fosse um jogo.Não é preciso diplomas, só ter 18 anos ou
              terminado o ensino obrigatório.
            </p>
            <p>
              Portugal tem talento mas precisa de mais de 30.000 programadores. A 42 chegou a Lisboa
              para responder a esta necessidade.
            </p>
          </div>
        </div>
      </div>
    </BackgroundImage>
  );
};

export default About;
