import React from 'react';
import logo from '../images/logo.svg';
import styles from './Header.module.scss';

const Header = (): React.ReactElement => {
  return (
    <header className={styles.header}>
      <div className={styles.headerContainer}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="" />
        </div>
      </div>
    </header>
  );
};

export default Header;
