import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import styles from './Statistics.module.scss';

import map from '../images/map.png';

const Statistics = (): React.ReactElement => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "42bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <BackgroundImage
      id="statistics"
      className={styles.wrapper}
      fluid={data.file.childImageSharp.fluid}
      alt=""
    >
      <div className={styles.overlay}>
        <div className={styles.container}>
          <div className={styles.numbersContainer}>
            <div className={styles.numberGroup}>
              <span className={styles.number}>+10.000</span>
              <span className={styles.label}>alunos no mundo</span>
            </div>
            <div className={styles.numberGroup}>
              <span className={styles.number}>31</span>
              <span className={styles.label}>campus em 21 países</span>
            </div>
            <div className={styles.numberGroup}>
              <span className={styles.number}>100%</span>
              <span className={styles.label}>de empregabilidade</span>
            </div>
          </div>
          <div className={styles.mapContainer}>
            <img src={map} alt="42 Worldwide" />
          </div>
        </div>
      </div>
    </BackgroundImage>
  );
};
export default Statistics;
