import React, { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import Form from './Form';

import styles from './OpenHouse.module.scss';
import { Helmet } from 'react-helmet';

const OpenHouse = (): React.ReactElement => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "openHouse.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  useEffect(() => {
    var exampleCallback = function () {
      console.log('Order complete!');
      if ('dataLayer' in window) {
        window.dataLayer.push({ event: 'OrderComplete' });
      }
    };
    window.EBWidgets.createWidget({
      // Required
      widgetType: 'checkout',
      eventId: '308892063477',
      iframeContainerId: 'eventbrite-widget-container-308892063477',
      // Optional
      iframeContainerHeight: 425, // Widget height in pixels. Defaults to a minimum of 425px if not provided
      onOrderComplete: exampleCallback, // Method called when an order has successfully completed
    });
  }, []);
  return (
    <div className={styles.sectionWrapper}>
      <span className={styles.fakeAnchor} id="visit-fake-anchor"></span>

      <BackgroundImage
        id="openHouse"
        className={styles.section}
        fluid={data.file.childImageSharp.fluid}
        alt=""
      >
        <div className={styles.overlay}>
          <div className={styles.container}>
            <div className={styles.content}>
              <h2>VEM CONHECER-NOS</h2>
              <p className={styles.description}>
                Este será um evento presencial, onde vais poder conhecer a escola e os seus alunos.
                Escolhe já o horário para nos vires visitar!
              </p>
              <span className={styles.span}>
                A inscrição é obrigatória, sendo necessário um bilhete por pessoa.
              </span>

              <span className={styles.span}>
                Os bilhetes estão disponíveis para diferentes horários e cada visita terá,
                aproximadamente, 1h.
              </span>

              <span className={styles.span}>Estamos à tua espera!</span>
            </div>
            <div
              id="eventbrite-widget-container-308892063477"
              className={styles.eventbriteContainer}
            ></div>
          </div>
        </div>
      </BackgroundImage>
      {/* <Form></Form> */}
    </div>
  );
};

export default OpenHouse;
