import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { useWindowSize } from '../utils/hooks';
import BackgroundImage from 'gatsby-background-image';
import Video from './Video';
import styles from './VideoSection.module.scss';

const VideoSection = (): React.ReactElement => {
  const { width } = useWindowSize();

  let maxVideoWidth: undefined | number = undefined;
  if (width) {
    if (width < 768) {
      maxVideoWidth = (width - 40) * 2; // *2 for retina
    } else if (width < 1200) {
      maxVideoWidth = width - 40 * 3; // *2 for retina
    } else {
      maxVideoWidth = 400;
    }
  }

  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "42bg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);
  return (
    <BackgroundImage
      id="video"
      className={styles.wrapper}
      fluid={data.file.childImageSharp.fluid}
      alt=" "
    >
      <div className={styles.overlay}>
        <div className={styles.container}>
          <h2>A 42 LISBOA NA TVI</h2>
          <div className={styles.videoWrapper}>
            <Video url="https://www.youtube.com/embed/5HfrVfX6EeM" maxWidth={maxVideoWidth}></Video>
          </div>
          {/* <iframe
            frameBorder="0"
            src="https://www.youtube.com/embed/5HfrVfX6EeM"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe> */}
        </div>
      </div>
    </BackgroundImage>
  );
};
export default VideoSection;
